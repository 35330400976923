<template>
	<screen size="small" class="auth-view">
		<template slot="body">
			<div class="auth-header">
				<h3>Reset Password</h3>
			</div>
			<div class="auth-content">
				<p>Enter a new password below to finish the reset process.</p>
				<form v-on:submit.prevent="onSubmit">
					<ui-label>
						<ui-input
							type="password"
							placeholder="New Password"
							v-model="password"
						/>
					</ui-label>
					<ui-label>
						<ui-button native-type="submit" :loading="isLoading"
							>Submit</ui-button
						>
					</ui-label>
				</form>
				<div class="roundup-row">
					<div class="forgot-password">
						<router-link :to="{ name: 'forgot-password' }"
							>Forgot Password?</router-link
						>
					</div>
					<div class="forgot-password">
						<router-link :to="{ name: 'login' }"
							>Back to Login</router-link
						>
					</div>
				</div>
			</div>
		</template>
	</screen>
</template>

<script>
import { mapActions } from 'vuex'
import Screen from '../../ui/Screen'
import UiButton from '../../ui/Button'
import UiInput from '../../ui/Input'
import UiLabel from '../../ui/Label'

export default {
	name: 'reset-password',
	components: { Screen, UiButton, UiInput, UiLabel },
	props: ['uuid'],
	data() {
		return {
			isLoading: false,
			password: ''
		}
	},
	methods: {
		...mapActions(['resetPassword']),
		onSubmit() {
			this.isLoading = true
			this.resetPassword({ token: this.uuid, password: this.password })
				.then(response => {
					this.isLoading = false
					this.$swal('Success', response.message).then(() => {
						this.navigate({ name: 'login' }, 'down')
					})
				})
				.catch(({ message }) => {
					this.isLoading = false
					this.$swal('Error', message)
				})
		}
	}
}
</script>

<style lang="scss">
.screen {
	&.auth-view {
		.forgot-password {
			font-size: 13px;
		}
	}
}
.roundup-row {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
}
</style>
